.footer {
  grid-row: 4/5;
  grid-column: 1/3;

  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 1.3vw 15vw 1vw 15vw;
  align-items: center;
  justify-content: center;
}

.footer-text {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #4b5158;
  font-size: calc(9.5px + 0.5vw);

  margin: calc(28px + 1vw) 0;
  box-sizing: border-box;
  width: 48vw;
  font-family: "Open Sans", sans-serif;
}
.footer-text span {
  font-weight: bold;
  color: #262a2e;
}

.footer-title {
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  font-size: calc(35px + 0.5vw);
  margin-top: 2vw;
  color: #262a2e;
}

.footer-footer {
  color: #4b5158;
  font-size: calc(8px + 0.5vw);
  width: 100%;
  margin-top: 28px;
  line-height: 1.8;
  text-align: center;
  margin-bottom: 0.5vw;
}

.footer .copy-btn {
  height: auto;
  text-decoration: none;
  box-sizing: border-box;
}

.footer-footer-bye {
  color: #858d95;
  font-size: calc(7px + 0.5vw);
  padding-top: 1vw;
  font-family: "Roboto Condensed", sans-serif;
  margin-bottom: 3px;
}

.back-home {
  color: #858d95;
  font-size: calc(6px + 0.5vw);
  font-family: serif;
}

@media screen and (min-width: 1020px) and (max-width: 1300px) {
  .footer .copy-btn div {
    font-size: calc(16px + 0.4vw);
  }

  .footer-text {
    font-size: calc(10px + 0.5vw);
  }
}

@media screen and (max-width: 1024px) {
  .footer-text {
    font-size: calc(8px + 2vw);
    margin: 30px 0;
    width: 100vw;
    font-family: "Open Sans", sans-serif;
    padding: 0 6vw;
  }

  .footer {
    padding: 5vw;
  }

  .footer-footer-bye,
  .back-home {
    font-size: calc(7px + 2vw);
  }

  .footer .copy-btn {
    padding: calc(0.3vw + 23px) calc(0.3vw + 10px);
    width: calc(2vw + 145px);
  }

  .footer .copy-btn div {
    font-size: calc(14px + 1vw);
  }

  .footer-title {
    font-size: calc(27.5px + 0.5vw);
  }
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
  .footer-title {
    font-size: calc(33px + 0.5vw);
    padding-top: 0.7vw;
  }

  .footer-text {
    margin: 4vw 0 5vw;
  }

  .footer .copy-btn {
    padding: calc(0.3vw + 15px) calc(0.3vw + 10px);
    width: calc(2vw + 130px);
  }

  .footer .copy-btn div {
    font-size: calc(14px + 0.5vw);
  }
}

@media screen and (min-width: 880px) and (max-width: 1024px) {
  .footer-text {
    font-size: calc(8px + 1vw);
    width: 100vw;
    padding: 0 6vw;
  }

  .footer {
    padding: 2vw;
  }

  .footer-footer-bye,
  .back-home {
    font-size: calc(7px + 1vw);
  }
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .footer .copy-btn {
    padding: calc(0.3vw + 17px) calc(0.3vw + 11px);
    width: calc(2vw + 135px);
  }
}

@media screen and (min-width: 1400px) {
  .footer .copy-btn {
    padding: calc(0.2vw + 12px) calc(0.2vw + 12px);
    width: calc(1vw + 130px);
  }
}

@media screen and (max-width: 500px) {
  .footer .copy-btn {
    padding: calc(0.3vw + 15px) calc(0.3vw + 10px);
    width: calc(2vw + 130px);
  }

  .footer .copy-btn div {
    font-size: calc(14px + 0.5vw);
  }
}

@media screen and (min-width: 1023px) and (min-height: 1100px) and (max-width: 2000px) {
  .footer-text {
    font-size: calc(8px + 1.5vw);
  }

  .footer .copy-btn {
    padding: calc(0.3vw + 23px) calc(0.3vw + 10px);
    width: calc(2vw + 145px);
  }

  .footer .copy-btn div {
    font-size: calc(10px + 1vw);
  }
}
