/* --- Base / Global Tweaks ------------------------------------------- */

/* 
   Introducing a few CSS variables for consistent spacing and colors. 
   Feel free to tweak to taste, but these keep values consistent.
*/
:root {
  --color-primary: #262a2e;
  --color-secondary: #4b5158;
  --color-accent: #fc0000; /* main accent color */
  --color-accent-light: #fcdf1d;
  --color-bg: #ffffff;
  --color-bg-alt: #fafafa;
  --color-bg-muted: #f0f1ec;
  --color-border: #e0e0e0;

  --spacing-section-top: 48px;
  --spacing-section-bottom: 48px;
  --spacing-section-sides: 5vw;
  --spacing-card-padding: 32px;
  --spacing-gap: 16px;

  --font-family-heading: "Roboto Condensed", sans-serif;
  --font-family-body: "Open Sans", sans-serif;
}

/* ------------------------------------------------------ */
/* GRID LAYOUT                                            */
/* ------------------------------------------------------ */
.main-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto auto;
  overflow-x: hidden;
}

/* ------------------------------------------------------ */
/* MAIN SECTION                                           */
/* ------------------------------------------------------ */
.main {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  background-color: var(--color-bg);
  /* Adjusted left/right padding for more balanced layout */
  padding: 0 12vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Give a bit more breathing room on top/bottom in large screens */
  padding-top: 60px;
  padding-bottom: 60px;
}

.title {
  font-family: var(--font-family-heading);
  font-weight: bold;
  font-size: 48px;
  color: var(--color-primary);
  line-height: 1.2;
  margin-bottom: 16px;
}

.sub-title {
  font-family: var(--font-family-body);
  font-size: 18px;
  color: var(--color-secondary);
  margin-bottom: 24px;
}

/* ------------------------------------------------------ */
/* FEATURES SECTION                                       */
/* ------------------------------------------------------ */
.features {
  background-color: var(--color-bg);
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* Balanced padding across all sides */
  padding: 4vw 8vw;
}

.feature {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px; /* unified spacing for each feature */
}

.check-icon {
  width: calc(1vw + 28px);
  height: calc(1vw + 28px);
  margin-top: 4px;
}

.feature:nth-child(4) .check-icon,
.feature:nth-child(5) .check-icon {
  margin-top: 2px;
}

.feature-text {
  font-family: var(--font-family-body);
  font-size: 16px;
  color: var(--color-secondary);
  margin-left: 24px;
  line-height: 1.5;
}

.feature-text span {
  font-weight: 600;
}

/* ------------------------------------------------------ */
/* COMMITS SECTION                                        */
/* ------------------------------------------------------ */
.commits {
  background-color: var(--color-bg);
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  padding-top: var(--spacing-section-top);
  padding-bottom: var(--spacing-section-bottom);
  padding-left: var(--spacing-section-sides);
  padding-right: var(--spacing-section-sides);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.commits::before {
  content: "";
  position: absolute;
  top: 0;
  left: 15vw;
  right: 15vw;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    var(--color-border),
    transparent
  );
}

.commits-title {
  color: var(--color-primary);
  font-family: var(--font-family-heading);
  text-align: center;
  margin-bottom: 24px;
  font-size: 40px;
  line-height: 1.3;
}

.commits-intro {
  display: flex;
  justify-content: center;
  color: var(--color-secondary);
  font-size: 16px;
  font-family: var(--font-family-body);
  font-weight: 500;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  margin: 0 0 40px 0;
  line-height: 1.6;
  text-align: center;
}

/* ------------------------------------------------------ */
/* PROJECT SHOWCASE                                       */
/* ------------------------------------------------------ */
.project-showcase {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-card-padding);
  background-color: var(--color-bg-alt);
  border-radius: 16px;
  position: relative;
  box-sizing: border-box;
}

.project-title {
  font-family: var(--font-family-heading);
  font-size: 24px;
  color: var(--color-primary);
  margin-bottom: 16px;
  line-height: 1.3;
}

.project-description {
  font-family: var(--font-family-body);
  font-size: 16px;
  color: var(--color-secondary);
  line-height: 1.6;
  margin-bottom: 32px;
}

.video-placeholder {
  display: none;
}

/* Future carousel navigation buttons */
.project-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% + 4vw);
  left: -2vw;
  display: flex;
  justify-content: space-between;
  pointer-events: none; /* Hide for now since we only have one project */
  opacity: 0; /* Hide for now */
}

.project-nav-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--color-bg);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  pointer-events: auto;
}

.project-nav-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* ------------------------------------------------------ */
/* COMMITS BOX                                            */
/* ------------------------------------------------------ */
.commit-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.commit {
  background-color: var(--color-bg);
  margin-bottom: calc(12px + 0.5vw);
  border-radius: 6px;
  padding: 1vw;
  width: 47vw;
  color: var(--color-secondary);
  font-size: calc(9px + 0.5vw);
  font-family: var(--font-family-body);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
}

.fresh {
  background-color: var(--color-accent-light);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 0.35vw);
  padding: 3px 6px;
  color: var(--color-accent);
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 20px;
  font-family: var(--font-family-body);
}

.commit-message {
  margin-bottom: 2px;
  color: var(--color-secondary);
}

.commit-message span {
  color: #858d95;
  font-style: italic;
}

.working-branch {
  padding-bottom: 0.7vw;
}

.working-branch span {
  color: #858d95;
  font-style: italic;
}

.git-remote {
  display: flex;
  justify-content: space-between;
}

.git-remote a {
  text-decoration: underline;
  color: var(--color-accent);
  font-size: calc(9px + 0.25vw);
}

.commit-date {
  color: #858d95;
  font-weight: lighter;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: calc(6px + 0.25vw);
}

/* ------------------------------------------------------ */
/* BUTTONS                                                */
/* ------------------------------------------------------ */
.commits-btn {
  background-color: var(--color-bg-muted);
  transition: all 0.06s ease-out;
  border: 2px solid var(--color-bg-muted);
  box-shadow: none;
  color: var(--color-accent);
  font-family: var(--font-family-body);
  font-size: calc(10px + 0.4vw);
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 38px;
  box-sizing: border-box;
  padding: 8px 16px;
}

.commits-btn:focus {
  outline: 0;
}

.cv {
  text-decoration: none;
  color: var(--color-primary);
}

/* ------------------------------------------------------ */
/* MEDIA QUERIES: TABLET / DESKTOP BREAKPOINTS           */
/* ------------------------------------------------------ */
@media screen and (min-width: 1020px) and (max-width: 1300px) {
  .main {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .features {
    padding: 4vw 10vw;
  }

  .commits {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .commits-intro {
    margin: 4vw 0;
    width: 100%;
  }

  .commit {
    width: 100%;
    font-size: calc(13px + 0.5vw);
    padding: 2.5vw;
  }

  .project-title {
    font-size: calc(15px + 0.5vw);
    padding-bottom: 2vw;
  }

  .commit-message {
    margin-bottom: 0.5vw;
  }
  .working-branch {
    padding-bottom: 2vw;
  }

  .git-remote a {
    font-size: calc(14px + 0.3vw);
  }

  .commit-date {
    font-size: calc(11px + 0.3vw);
  }
}

@media screen and (min-width: 880px) and (max-width: 1024px) {
  .main {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .features {
    padding: 4vw 10vw;
  }

  .commits {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .commits-intro {
    margin: 4vw 0;
    width: 100%;
  }

  .commit {
    width: 100%;
    font-size: calc(13px + 0.5vw);
    padding: 2.5vw;
  }
}

/* ------------------------------------------------------ */
/* MEDIA QUERIES: MOBILE                                  */
/* ------------------------------------------------------ */
@media screen and (max-width: 880px) {
  .main-grid {
    grid-template-rows: auto auto auto auto auto;
  }

  .title {
    font-size: 40px;
  }

  .sub-title {
    font-size: 16px;
  }

  .commit-box {
    width: 100%;
  }

  .main {
    grid-column: 1/3;
    grid-row: 2/3;
    height: auto;
    padding: 40px 5vw;
    box-sizing: border-box;
    width: 100%;
  }

  .features {
    grid-column: 1/3;
    grid-row: 3/4;
    padding: 5vw;
    box-sizing: border-box;
    width: 100%;
    background-color: var(--color-bg-muted);
  }

  .feature {
    margin-bottom: 7vw;
  }

  .feature-text {
    margin-left: 4vw;
  }

  .commits {
    grid-row: 4/5;
    padding: 40px 5vw;
  }

  .commits::before {
    left: 5vw;
    right: 5vw;
  }

  .project-showcase {
    padding: 24px 20px;
  }

  .project-title,
  .project-description {
    padding: 0 4vw;
  }

  .video-placeholder {
    width: 100%;
    height: 56vw;
  }

  .project-nav {
    width: calc(100% + 8vw);
    left: -4vw;
  }

  .project-nav-button {
    width: 40px;
    height: 40px;
  }

  .commits-intro {
    width: 100%;
    font-size: calc(14px + 0.5vw);
    text-align: center;
    padding: 8vw 1vw 10vw 1vw;
  }

  .commit {
    width: 100%;
    font-size: calc(13px + 0.5vw);
    padding: 5vw;
    box-sizing: border-box;
  }

  .project-title {
    font-size: 20px;
    padding-bottom: 4vw;
  }

  .commit-message {
    margin-bottom: 0.5vw;
  }
  .working-branch {
    padding-bottom: 4vw;
  }

  .git-remote a {
    font-size: calc(9px + 1.3vw);
  }

  .commit-date {
    font-size: calc(6px + 1.3vw);
  }

  .commits-btn {
    font-size: calc(12px + 1vw);
  }

  .commits-title {
    font-size: 32px;
    padding-top: 6vw;
  }

  .main-grid .footer {
    grid-row: 5/6;
  }

  .project-showcase {
    width: 100%;
    box-sizing: border-box;
  }

  .project-description {
    padding: 0 1vw;
  }
}

/* Even smaller devices */
@media screen and (max-width: 440px) {
  .feature {
    margin-bottom: 10vw;
  }

  .title {
    font-size: 32px;
  }

  .sub-title {
    font-size: 16px;
  }

  .feature-text {
    font-size: 15px;
  }

  .commits-title {
    font-size: 28px;
  }

  .project-title {
    font-size: 18px;
  }

  .project-description,
  .commits-intro,
  .video-placeholder {
    font-size: 15px;
  }
}

@media screen and (max-width: 350px) {
  .feature {
    margin-bottom: 11vw;
  }
}

/* ------------------------------------------------------ */
/* MEDIA QUERIES: OTHER RANGES                            */
/* ------------------------------------------------------ */
@media screen and (min-width: 640px) and (max-width: 880px) {
  .feature-text {
    margin-left: 2.6vw;
    font-size: calc(18px + 0.5vw);
  }

  .sub-title {
    font-size: calc(18px + 0.5vw);
  }

  .commits-title {
    font-size: calc(30px + 0.5vw);
  }

  .commits-intro {
    padding: 4vw 1vw 5vw 1vw;
    font-size: calc(18px + 0.5vw);
  }

  .check-icon {
    margin-top: 0;
  }

  .commit {
    padding: 3vw;
    font-size: calc(15px + 0.5vw);
  }

  .project-title {
    font-size: calc(18px + 0.5vw);
    padding-bottom: 3vw;
  }

  .working-branch {
    padding-bottom: 3vw;
  }
}

/* Larger screens if needed */
@media screen and (min-width: 1200px) and (max-width: 1500px) {
}

@media screen and (min-width: 1500px) {
}

/* ------------------------------------------------------ */
/* PROJECT CALL TO ACTION                                 */
/* ------------------------------------------------------ */
.project-cta {
  font-family: var(--font-family-body);
  font-size: calc(14px + 0.5vw);
  color: var(--color-secondary);
  text-align: center;
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5vw;
}

.project-cta .copy-btn {
  margin-top: 0.5vw;
}

.copy-btn {
  background-color: var(--color-accent);
  transition: all 0.06s ease-out;
  padding: calc(0.3vw + 12px) calc(0.3vw + 10px);
  width: calc(2vw + 130px);
  border: 2px solid var(--color-accent);
  box-shadow: 0;
  color: #fff;
  font-family: var(--font-family-body);
  font-size: calc(14px + 0.4vw);
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
}

.btn-success {
  background-color: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.demo-link {
  display: block;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  text-decoration: none;
  position: relative;
  transition: transform 0.3s ease;
}

.demo-link:hover {
  transform: translateY(-4px);
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.try-demo {
  display: inline-block;
  margin-top: 16px;
  color: var(--color-accent);
  font-family: var(--font-family-body);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.demo-link:hover .try-demo {
  text-decoration: underline;
}
