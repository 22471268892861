.header {
  grid-column: 1 / 3;
  grid-row: 1/2;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 2.5vh 15vw 2.5vh 15vw;
  align-items: center;
  border-top: 4px solid #fc0000;
}

.header a {
  text-decoration: none;
}

.name {
  position: relative;
  color: #262a2e;
  font-family: "Roboto Condensed", sans-serif;
  font-size: calc(18px + 1.2vw);
}

.copy-btn {
  background-color: #fc0000;
  color: #ffeded;
  display: flex;
  border-radius: 6px;
  align-items: center;
  border: 3px solid #fc0000;
  cursor: pointer;
  transition: all 0.4s ease-out;
  text-decoration: none;
}

.header .copy-btn {
  padding: 8px 16px;
  min-width: 120px;
}

.copy-btn div {
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
}

.btn-success {
  background-color: #262a2e;
  border: 3px solid #262a2e;
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .header {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 2.5vh 5vw 2.5vh 5vw;
  }
  .header .copy-btn {
    padding: 8px 16px;
    min-width: 110px;
  }

  .name {
    font-size: calc(20px + 1.2vw);
  }
}

@media screen and (max-width: 440px) {
  .header .copy-btn {
    padding: 6px 12px;
    min-width: 100px;
  }

  .header .copy-btn div {
    font-size: 13px;
  }
}

@media screen and (min-width: 440px) and (max-width: 880px) {
  .header .copy-btn div {
    font-size: calc(8.5px + 1.3vw);
  }
}

@media screen and (min-width: 700px) and (max-width: 880.5px) {
  .header .copy-btn {
    width: 12vw;
    padding: 0.5vw 0.5vw;
  }

  .header .copy-btn div {
    font-size: calc(9px + 1vw);
  }
}

@media screen and (min-width: 880px) and (max-width: 1200px) {
  .header .copy-btn {
    width: 9.5vw;
  }

  .header .copy-btn div {
    font-size: calc(8.5px + 0.8vw);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .header .copy-btn {
    width: 7vw;
  }

  .header .copy-btn div {
    font-size: calc(8.12px + 0.6vw);
  }
}

@media screen and (min-width: 1500px) {
  .header .copy-btn {
    height: auto;
    width: 5.6vw;
  }
}

@media screen and (min-width: 1023px) and (min-height: 1100px) and (max-width: 1400px) {
  .header {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}
